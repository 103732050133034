<template>
    <div>
        <div class="box">
            <el-page-header @back="$router.go(-1)" :content="$t('tOrderDetail')"></el-page-header>
        </div>
        <el-card class="content mt20">
            <el-form label-position="left" :model="ruleForm" ref="ruleForm" label-width="100px" class="ruleFrom row">
                <el-form-item :label="$t('order').order_id" prop="order_id" class="col-6 mb0">
                    <div>{{ruleForm.order_id}}</div>
                </el-form-item>
                <el-form-item :label="$t('order').status" prop="status" class="col-6 mb0">
                    <div>{{status(ruleForm.status)}}</div>
                </el-form-item>
                <el-form-item :label="$t('order').begin_time" prop="begin_time" class="col-6 mb0">
                    <div>{{ruleForm.begin_time}}</div>
                </el-form-item>
                <el-form-item :label="$t('order').end_time" prop="end_time" class="col-6 mb0">
                    <div>{{ruleForm.end_time}}</div>
                </el-form-item>
                <el-form-item :label="$t('order').price" prop="price" class="col-6 mb0">
                    <div>{{ruleForm.price}}</div>
                </el-form-item>
                <el-form-item v-if="ruleForm.status === 2" :label="$t('order').paytime" prop="paytime" class="col-6 mb0">
                    <div>{{ruleForm.paytime}}</div>
                </el-form-item>
                <el-form-item :label="$t('order').score" prop="score" class="col-6 mb0">
                    <div>{{ruleForm.score}}</div>
                </el-form-item>
            </el-form>
        </el-card>
        <el-card class="content mt20">
            <el-form label-position="left" :model="ruleForm" ref="ruleForm" label-width="100px" class="row">
                <el-form-item :label="$t('order.user_username')" prop="user_username" class="col-6 mb0">
                    <div>{{ruleForm.user.username}}</div>
                </el-form-item>
                <el-form-item :label="$t('order.subject_name')" prop="subject_name" class="col-6 mb0">
                    <div>{{ruleForm.subject.name}}</div>
                </el-form-item>
                <el-form-item :label="$t('order.title')" prop="title" class="col-12 mb0">
                    <div>{{ruleForm.title}}</div>
                </el-form-item>
                <el-form-item :label="$t('order.desc')" prop="desc" class="col-12 mb0">
                    <div>{{ruleForm.desc}}</div>
                </el-form-item>
                <el-form-item :label="$t('order.service')" prop="service" class="col-12 mb0">
                    <div>{{ruleForm.service}}</div>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import { orderDetail } from '@/api/index'
export default {
    name:'orderDetail',
    data(){
        return {
            ruleForm:{
                title:'',
                user:{
                    id:'',
                    username:''
                },
                desc:'',
                service:'',
                subject:{
                    id:'',
                    name:''
                },
                order_id:'',
                price:0,
                paytime:'',
                begin_time:'',
                end_time:'',
                status:0
            }
        }
    },
    computed:{
        id(){
			return Number.isNaN(Number(this.$route.query.id)) ? undefined : Number(this.$route.query.id)
        },
    },
    methods:{
        status(status){
            switch(status){
                case 1:
                    return this.$t('order.status1')
                case 2:
                    return this.$t('order.status2')
                case 3:
                    return this.$t('order.status3')
            }
        },
        async get_info(){
            const information = await orderDetail(this.id)
            this.ruleForm = information
        }
    },
    created(){
        this.get_info()
    },
}
</script>
<style lang="scss" scoped>
.v-for-span + .v-for-span::before{
    content:'/'
}
    /deep/.el-form-item__content{
        word-wrap: break-word;
        font-weight: bold;
    }
</style>